import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

@customElement("ptc-drawer")
export class Drawer extends LitElement {
    @property({ type: Boolean })
    collapsed: boolean = false;

    @property({ type: Number })
    collapsedHeight = 0;

    @state()
    private _innerSize = 0;

    @query(".inner")
    private _inner: HTMLDivElement;

    private _mutationObserver = new MutationObserver(() => this.updateInnerSize());
    private _intersectionObserver = new IntersectionObserver(() => this.updateInnerSize());

    connectedCallback() {
        super.connectedCallback();
        this._mutationObserver.observe(this, { childList: true, subtree: true });
        this._intersectionObserver.observe(this);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._mutationObserver.disconnect();
        this._intersectionObserver.disconnect();
    }

    updated() {
        this.style.height = this.collapsed
            ? `${Math.min(this.collapsedHeight, this._innerSize)}px`
            : `${this._innerSize}px`;
    }

    updateInnerSize() {
        setTimeout(() => (this._innerSize = (this._inner && this._inner.offsetHeight) || 0), 100);
    }

    static styles = [
        css`
            :host {
                display: block;
                overflow: hidden;
                transition: height 0.3s;
            }

            :host([collapsed]) {
                height: 0;
            }
        `,
    ];

    render() {
        return html`
            <div class="inner">
                <slot></slot>
            </div>
        `;
    }
}
