import { LitElement } from "lit";
import { State } from "@pentacode/core/src/app";
import { app } from "../init";

type Constructor<T> = new (...args: any[]) => T;

export const StateMixin = <T extends Constructor<LitElement>>(baseElement: T) =>
    class extends baseElement {
        get state() {
            return app.state;
        }

        private _stateHandler = this.stateChanged.bind(this);

        connectedCallback() {
            super.connectedCallback();

            app.subscribe(this._stateHandler);
            this.stateChanged(this.state);
            app.loaded.then(() => this.appLoaded());
        }

        disconnectedCallback() {
            app.unsubscribe(this._stateHandler);

            super.disconnectedCallback();
        }

        /**
         * The `stateChanged(state)` method will be called when the state is updated.
         */
        protected stateChanged(_state: State) {
            this.requestUpdate("state");
        }

        protected appLoaded() {}
    };
